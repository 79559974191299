// Enumerations
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';
// Resolvers
import { initRecoveryKeyResolver } from '../resolvers/recovery-key.resolvers';
// Types
import { RecoveryKeyHandlersInterface } from './types/recovery-key.handlers.type';
import { ChallengeSssApiResponseType } from 'types/challenge/challenge-sss-api-response.type';

const setRecoveryOptionHandler = ({
  handleRecoveryOptionItemRadioClickTracking,
  id,
  setCurrentStep,
  currentStep,
  setRecoveryOption,
}) => {
  handleRecoveryOptionItemRadioClickTracking(id);
  setRecoveryOption(id);
  if (currentStep === WizardStepsEnumeration.ONE) setCurrentStep(WizardStepsEnumeration.TWO);
};

const resendOtpHandler = async ({
  email: username,
  handleRestart,
  phoneNumber: number,
  prefix,
  setAuthData,
  setResult,
}) => {
  const phoneNumber = `${prefix}${number}`;

  const [response, error] = await initRecoveryKeyResolver({ username, phoneNumber });

  if (error) {
    setResult(false);
  } else {
    handleRestart();
    setAuthData(response as ChallengeSssApiResponseType);
  }
};

const RecoveryKeyHandlers = ({
  handleRecoveryOptionItemRadioClickTracking,
  setRecoveryOption,
  email,
  handleRestart,
  phoneNumber,
  prefix,
  setAuthData,
  setResult,
  setCurrentStep,
  currentStep,
}: RecoveryKeyHandlersInterface): {
  handleSetRecoveryOption: (id: string) => void;
  handleResendOtp: VoidFunction;
  handleInitiateOtp: VoidFunction;
} => ({
  handleSetRecoveryOption: id =>
    setRecoveryOptionHandler({
      handleRecoveryOptionItemRadioClickTracking,
      id,
      currentStep,
      setCurrentStep,
      setRecoveryOption,
    }),
  handleInitiateOtp: () => {
    resendOtpHandler({
      email,
      handleRestart,
      phoneNumber,
      prefix,
      setAuthData,
      setResult,
    });
    setCurrentStep(WizardStepsEnumeration.TWO);
  },
  handleResendOtp: () =>
    resendOtpHandler({
      email,
      handleRestart,
      phoneNumber,
      prefix,
      setAuthData,
      setResult,
    }),
});

export { RecoveryKeyHandlers };
