// Types
import { RequestSignatureKeyConfirmationHandlersReturnType } from './types/request-signature-key-confirmation.handlers-return.type';
import { RequestSignatureKeyConfirmationHandlersType } from './types/request-signature-key-confirmation.handlers.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const goToDashboardButtonHandler = ({
  handleGoToDashboardButtonClickTracking,
  navigate,
  route,
  onSuccessHybridFlow,
}: RequestSignatureKeyConfirmationHandlersType) => {
  handleGoToDashboardButtonClickTracking();
  onSuccessHybridFlow?.({
    url: generateAbsoluteRoute(route),
    navigate,
  });
};

const RequestSignatureKeyConfirmationHandlers = ({
  handleGoToDashboardButtonClickTracking,
  navigate,
  route,
  onSuccessHybridFlow,
}: RequestSignatureKeyConfirmationHandlersType): RequestSignatureKeyConfirmationHandlersReturnType => ({
  handleGoToDashboardButtonClick: () =>
    goToDashboardButtonHandler({
      handleGoToDashboardButtonClickTracking,
      navigate,
      route,
      onSuccessHybridFlow,
    }),
});

export { RequestSignatureKeyConfirmationHandlers };
