// Vendors
import React, { useState, useContext, createContext, SetStateAction, Dispatch } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import {
  RecoveryKeyAuthDataType,
  RecoveryKeyContextType,
  RecoveryOptionContextInterface,
} from './types/recovery-key.context.type';
// Enums
import { PasswordRecoveryOptionsEnumeration } from 'enumerations/sss/password-recovery-options.enumeration';
import { RecoveryKeyCCRecoveryOptionEnumeration } from '../enumerations/recovery-key.enumerations';

const RecoveryKeyContext = createContext<RecoveryKeyContextType>({} as RecoveryKeyContextType);
const RecoveryOptionContext = createContext<RecoveryOptionContextInterface>({
  recoveryOption: undefined,
  setRecoveryOption: () => undefined,
  verificationData: undefined,
  setVerificationData: () => undefined,
});

const RecoveryKeyContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<RecoveryKeyContextType>> => {
  const [authData, setAuthData] = useState<RecoveryKeyAuthDataType>({});
  const [email, setEmail] = useState<string>();
  const [pin, setPin] = useState<string>('');
  const [last4DigitsPan, setLast4DigitsPan] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [prefix, setPrefix] = useState<string>('');
  const [fetching, setFetching] = useState<boolean>(false);

  return (
    <RecoveryKeyContext.Provider
      value={{
        authData,
        email,
        last4DigitsPan,
        phoneNumber,
        pin,
        prefix,
        fetching,
        setFetching,
        setAuthData,
        setEmail,
        setLast4DigitsPan,
        setPhoneNumber,
        setPin,
        setPrefix,
      }}
    >
      {children as React.ReactNode}
    </RecoveryKeyContext.Provider>
  );
};
const RecoveryOptionContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<RecoveryOptionContextInterface>> => {
  const [recoveryOption, setRecoveryOption] = useState<
    PasswordRecoveryOptionsEnumeration | RecoveryKeyCCRecoveryOptionEnumeration
  >();
  const [verificationData, setVerificationData] = useState<boolean>();

  return (
    <RecoveryOptionContext.Provider
      value={{
        recoveryOption,
        verificationData,
        setVerificationData,
        setRecoveryOption,
      }}
    >
      {children as React.ReactNode}
    </RecoveryOptionContext.Provider>
  );
};

const useRecoveryKeyContextConsumerHook = (): RecoveryKeyContextType =>
  useContext(RecoveryKeyContext);

const useRecoveryOptionContextConsumerHook = (): RecoveryOptionContextInterface =>
  useContext(RecoveryOptionContext);

/* eslint-disable */
const RecoveryKeyContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<RecoveryKeyContextType>> =>
    (
      <RecoveryKeyContextProvider>
        <RecoveryOptionContextProvider>
          <Component {...props} />
        </RecoveryOptionContextProvider>
      </RecoveryKeyContextProvider>
    );

export {
  RecoveryKeyContextConsumerHOC,
  useRecoveryKeyContextConsumerHook,
  useRecoveryOptionContextConsumerHook,
};
