const DOMAIN_CC = 'cobrandedCC.services.userSettings.recoveryAccess.';
const DOMAIN = 'cobranded.services.userSettings.privateArea.';

const FIRST_STEP_TITLE = `${DOMAIN_CC}firstStep.title`,
  FIRST_STEP_DESCRIPTION = `${DOMAIN_CC}firstStep.description`,
  FIRST_STEP_ACTION = `${DOMAIN_CC}firstStep.action`,
  FIRST_STEP_ITEM_1 = `${DOMAIN_CC}firstStep.radioItem.first`,
  FIRST_STEP_ITEM_2 = `${DOMAIN_CC}firstStep.radioItem.second`,
  FIRST_STEP_ITEM_3 = `${DOMAIN_CC}firstStep.radioItem.third`,
  FIRST_STEP_ITEM_4 = `${DOMAIN_CC}firstStep.radioItem.fourth`,
  SIDEBAR_TITLE = `${DOMAIN_CC}sidebar.title`,
  SIDEBAR_DESCRIPTION = `${DOMAIN_CC}sidebar.description`,
  SIDEBAR_ACCESS_SMS_TITLE = `${DOMAIN_CC}sidebarAccessSMS.title`,
  SIDEBAR_ACCESS_SMS_BUTTON = `${DOMAIN_CC}sidebarAccessSMS.button`,
  CLIENT_NAME = `${DOMAIN_CC}subtitle.clientName`,
  PHONE_CONFIRMATION = `${DOMAIN_CC}description.phoneConfirmation`,
  FIRST_OPTION_BOX2_P1 = `${DOMAIN_CC}secondStep.firstOption.secondBox.firstDescription`,
  FIRST_OPTION_BOX2_L1_I1 = `${DOMAIN_CC}secondStep.firstOption.secondBox.firstList.firstItem`,
  FIRST_OPTION_BOX2_L1_I2 = `${DOMAIN_CC}secondStep.firstOption.secondBox.firstList.secondItem`,
  FIRST_OPTION_BOX2_P2 = `${DOMAIN_CC}secondStep.firstOption.secondBox.secondDescription`,
  FIRST_OPTION_BOX2_L2_I1 = `${DOMAIN_CC}secondStep.firstOption.secondBox.secondList.firstItem`,
  FIRST_OPTION_BOX2_L2_I2 = `${DOMAIN_CC}secondStep.firstOption.secondBox.secondList.secondItem`,
  FIRST_OPTION_MESSAGE = `${DOMAIN_CC}secondStep.firstOption.message`,
  ACCESS_SMS_TITLE = `${DOMAIN_CC}secondStep.firstOption.accessSMS.title`,
  ACCESS_SMS_BOX1_TITLE = `${DOMAIN_CC}secondStep.firstOption.accessSMS.firstStep.firstBox.title`,
  ACCESS_SMS_BOX1_DESCRIPTION = `${DOMAIN_CC}secondStep.firstOption.accessSMS.firstStep.firstBox.description`,
  ACCESS_SMS_BOX2_DESCRIPTION = `${DOMAIN_CC}secondStep.firstOption.accessSMS.firstStep.secondBox.description`,
  ACCESS_SMS_BOX2_MESSAGE = `${DOMAIN_CC}secondStep.firstOption.accessSMS.firstStep.secondBox.message`,
  ACCESS_SMS_INPUT_EMAIL = `${DOMAIN_CC}secondStep.firstOption.accessSMS.firstStep.clientEmail`,
  ACCESS_SMS_INPUT_PHONE = `${DOMAIN_CC}secondStep.firstOption.accessSMS.firstStep.clientPhoneNumber`,
  ACCESS_SMS_INPUT_PHONE_ERROR = `${DOMAIN_CC}secondStep.firstOption.accessSMS.firsStep.clientPhoneNumber.error`,
  ACCESS_SMS_SIGNATURE_TITLE =
    'cobranded.services.userSettings.privateArea.cbRecoveryKey.recoveryKey.step4.signatureKey.title',
  ACCESS_SMS_SIGNATURE_DESCRIPTION = `${DOMAIN_CC}secondStep.firstOption.accessSMS.secondStep.description`,
  ACCESS_SMS_OTP_TITLE = `${DOMAIN_CC}secondStep.firstOption.accessSMS.secondStep.title`,
  ACCESS_SMS_OTP_DESCRIPTION = `${DOMAIN_CC}secondStep.firstOption.accessSMS.secondStep.description`,
  SECOND_OPTION_USER_ACTION = `${DOMAIN_CC}secondStep.secondOption.secondBox.firstDescription`,
  SECOND_OPTION_BOX2_P2 = `${DOMAIN_CC}secondStep.secondOption.secondBox.secondDescription`,
  MANDATORY_FILES = `${DOMAIN_CC}secondStep.mandatoryFiles`,
  FILES_SENT_BY_POST = `${DOMAIN_CC}secondStep.sendByPost`,
  AFTER_RECEIVING_FILES = `${DOMAIN_CC}secondStep.afterReceivingFiles`,
  UPDATE_ACCESS_KEY = `${DOMAIN_CC}secondStep.updateAccessKey`,
  THIRD_OTION_B2_P1 = `${DOMAIN_CC}secondStep.thirdOption.secondBox.firstDescription`,
  THIRD_OPTION_BOX1_L1_I1 = `${DOMAIN_CC}secondStep.forthOption.firstBox.firstList.firstItem`,
  THIRD_OPTION_BOX1_L1_I2 = `${DOMAIN_CC}secondStep.forthOption.firstBox.firstList.secondItem`,
  SAY_YOUR_ADDRESS = `${DOMAIN_CC}secondStep.sayYourAddress`,
  READING_WARNING = `${DOMAIN_CC}secondStep.readingWarning`,
  FOURTH_OPTION_LIST_I1 = `${DOMAIN_CC}secondStep.forthOption.firstList.firstItem`,
  FOURTH_OPTION_LIST_I2 = `${DOMAIN_CC}secondStep.forthOption.firstList.secondItem`,
  FOURTH_OPTION_LIST_I3 = `${DOMAIN_CC}secondStep.forthOption.firstList.thirdItem`,
  ONCE_RECEIVING_FILES = `${DOMAIN_CC}secondStep.onceReceivingFiles`,
  PAGE_TITLE = `${DOMAIN}cbRecoveryKey.recoveryKey.pageTitle`,
  PHONE_NUMBER = `${DOMAIN}personalInformation.personalDetails.contactInformation.phoneNumber`,
  BIRTHDATE = `${DOMAIN_CC}personalInformation.personalDetails.birthdate`,
  ADDRESS = `${DOMAIN}personalInformation.personalDetails.contactInformation.address`,
  EMAIL = `${DOMAIN}personalInformation.personalDetails.contactInformation.email`,
  CORRECT_INFORMATIOM = `${DOMAIN_CC}secondStep.correctInformation`,
  WRONG_INFORMATIOM = `${DOMAIN_CC}secondStep.wrongInformation`,
  SEND_INFORMATION = `${DOMAIN_CC}secondStep.sendInformation`,
  AFTER_VERIFICATION = `${DOMAIN_CC}thirdStep.correctInformation`,
  DONE = `${DOMAIN}commons.genericButton.done`;

export {
  DONE,
  PHONE_NUMBER,
  EMAIL,
  ADDRESS,
  BIRTHDATE,
  AFTER_VERIFICATION,
  SEND_INFORMATION,
  CORRECT_INFORMATIOM,
  WRONG_INFORMATIOM,
  PAGE_TITLE,
  FIRST_STEP_TITLE,
  FIRST_STEP_DESCRIPTION,
  FIRST_STEP_ITEM_1,
  FIRST_STEP_ITEM_2,
  FIRST_STEP_ITEM_3,
  FIRST_STEP_ITEM_4,
  SIDEBAR_TITLE,
  SIDEBAR_DESCRIPTION,
  SIDEBAR_ACCESS_SMS_TITLE,
  SIDEBAR_ACCESS_SMS_BUTTON,
  CLIENT_NAME,
  PHONE_CONFIRMATION,
  FIRST_STEP_ACTION,
  FIRST_OPTION_BOX2_P1,
  FIRST_OPTION_BOX2_L1_I1,
  FIRST_OPTION_BOX2_L1_I2,
  FIRST_OPTION_BOX2_P2,
  FIRST_OPTION_BOX2_L2_I1,
  FIRST_OPTION_BOX2_L2_I2,
  FIRST_OPTION_MESSAGE,
  ACCESS_SMS_TITLE,
  ACCESS_SMS_BOX1_TITLE,
  ACCESS_SMS_BOX1_DESCRIPTION,
  ACCESS_SMS_BOX2_DESCRIPTION,
  ACCESS_SMS_BOX2_MESSAGE,
  ACCESS_SMS_INPUT_EMAIL,
  ACCESS_SMS_INPUT_PHONE,
  ACCESS_SMS_INPUT_PHONE_ERROR,
  ACCESS_SMS_SIGNATURE_TITLE,
  ACCESS_SMS_SIGNATURE_DESCRIPTION,
  ACCESS_SMS_OTP_TITLE,
  ACCESS_SMS_OTP_DESCRIPTION,
  SECOND_OPTION_USER_ACTION,
  SECOND_OPTION_BOX2_P2,
  FOURTH_OPTION_LIST_I1,
  FOURTH_OPTION_LIST_I2,
  FOURTH_OPTION_LIST_I3,
  MANDATORY_FILES,
  THIRD_OPTION_BOX1_L1_I1,
  THIRD_OPTION_BOX1_L1_I2,
  FILES_SENT_BY_POST,
  AFTER_RECEIVING_FILES,
  UPDATE_ACCESS_KEY,
  THIRD_OTION_B2_P1,
  SAY_YOUR_ADDRESS,
  READING_WARNING,
  ONCE_RECEIVING_FILES,
};
