// Vendors
import React from 'react';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
import { RecoveryKeySidebarComponent } from './components/sidebar/recovery-key-sidebar.component';
import OrchestratorFooterComponent from 'containers/orchestrator/components/footer/orchestrator-footer.component';
import TitleSectionComponent from 'components/title-section/title-section.component';
import WizardComponent from 'components/wizard/wizard.component';
// Constants
import { RECOVERY_KEY_LOGO_PROPS } from './constants/recovery-key.constants';
// Contexts
import { RecoveryKeyContextConsumerHOC } from './contexts/recovery-key.context';
// Hooks
import { useRecoveryKey } from './hooks/recovery-key.hook';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import {
  RecoveryKeyContainerStyled,
  RecoveryKeyHeaderContainerStyled,
  RecoveryKeyContentContainerStyled,
} from './recovery-key.container.styled';

const RecoveryKeyContainer = (): React.ReactElement => {
  const { steps } = useRecoveryKey({});

  return (
    <RecoveryKeyContainerStyled>
      <RecoveryKeyHeaderContainerStyled>
        <AssetComponent {...RECOVERY_KEY_LOGO_PROPS} />
      </RecoveryKeyHeaderContainerStyled>
      <RecoveryKeyContentContainerStyled>
        <TitleSectionComponent />
        <LayoutGridContentComponentStyled>
          <LayoutGridContentLeftComponentStyled>
            {steps && <WizardComponent {...steps} />}
          </LayoutGridContentLeftComponentStyled>
          <LayoutGridContentRightComponentStyled>
            <RecoveryKeySidebarComponent />
          </LayoutGridContentRightComponentStyled>
        </LayoutGridContentComponentStyled>
      </RecoveryKeyContentContainerStyled>
      <OrchestratorFooterComponent />
    </RecoveryKeyContainerStyled>
  );
};

export { RecoveryKeyContainer };

export default RecoveryKeyContextConsumerHOC(RecoveryKeyContainer);
